import React from "react"
import DynamicComponent from "../DynamicComponent/index"
import SbEditable from "storyblok-react"

import "./styles.scss"

const Page = ({ blok }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)

    let pageClass = "";

    if (blok.header_spacing) {
      pageClass ="top-spacing"
    }
  
  return (
    <div className={pageClass}>
      <SbEditable content={blok}>
        { content }
      </SbEditable>
    </div>
  )
}

export default Page