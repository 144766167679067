import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Button from "../../Button"

import "./styles.scss"

class Mantra extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="container">
                    <div className="mantra">
                        <div className="words">
                        {this.blok.words.map((word, index) => {
                            return (<span key={index} className="word">{word.word}. </span>)
                        })}
                        </div>
                        <Button to="/kontakt" label="Nachricht schreiben" type="primary" />
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Mantra