import React, { Component } from "react"

import "./styles.scss"

class PoweredBy extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <div className="container">
                <div className="powered-by">
                    <h3>Design und Entwicklung der Webseite</h3>
                    <a href="https://condu.co" target="_blank" rel="noreferrer">
                        <picture>
                            <source srcSet="https://a.triaz-pr.de/200x0/filters:format(webp)/f/103293/2835x613/55e9343126/conducolabs-logo.jpg 1x, https://a.triaz-pr.de/400x0/filters:format(webp)/f/103293/2835x613/55e9343126/conducolabs-logo.jpg 2x" type="image/webp" />
                            <source srcSet="https://a.triaz-pr.de/200x0/f/103293/2835x613/55e9343126/conducolabs-logo.jpg 1x, https://a.triaz-pr.de/400x0/f/103293/2835x613/55e9343126/conducolabs-logo.jpg 2x" type="image/jpeg" />
                            <img loading="lazy" src="https://a.triaz-pr.de/200x0/f/103293/2835x613/55e9343126/conducolabs-logo.jpg" alt="conduco labs" width="200" height="43" />
                        </picture>
                    </a>
                    <p>conduco labs</p>
                    <p><a href="https://condu.co" target="_blank" rel="noreferrer">condu.co</a></p>
                </div>
            </div>
          )
    }
}

export default PoweredBy