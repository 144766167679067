import React, { Component } from "react"
import SbEditable from "storyblok-react"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"


class ClientsOverview extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        
        const people = this.blok.project_pr.sort((a, b) => {
            if (a.content.lastname > b.content.lastname) {
                return 1
            } else if (b.content.lastname > a.content.lastname) {
                return -1
            } else {
                return 0
            }
        });
            
        const brands = this.blok.partner_pr.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (b.name > a.name) {
                return -1
            } else {
                return 0
            }
        });
        
        
        return (
            <SbEditable content={this.blok}>
                <div className="container">
                    <div className="clients-overview" id="fuer-wen-wir-arbeiten">
                        <div className="project-pr">
                            <h2>Menschen</h2>
                            <ul className="clients-grid">
                            {people.map((client, index) => {
                                return (
                                    <li key={index}>
                                        <a href={`/${client.full_slug}`} className="client">
                                            <picture>
                                                <source srcSet={`${ ImagesService(client.content.profile_picture.filename, true, { path: '228x342/smart/filters:format(webp)'}) } 1x, ${ ImagesService(client.content.profile_picture.filename, true, { path: '456x682/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                <source srcSet={`${ ImagesService(client.content.profile_picture.filename, true, { path: '228x342/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(client.content.profile_picture.filename, true, { path: '456x682/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                <img loading="lazy" src={ImagesService(client.content.profile_picture.filename, true, { path: '228x342/smart/filters:format(jpeg):quality(60)'})} alt={client.name} width="228" height="342" />
                                            </picture>
                                            <div className="nametag">
                                                <div className="name">{client.content.firstname} {client.content.lastname}</div><br/>
                                                <div className="position">{client.content.profession}</div>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="company-pr">
                            <h2>Marken</h2>
                            <ul className="clients-grid">
                            {brands.map((client, index) => {
                                return (
                                    <li key={index}>
                                        <a href={client.website.cached_url} rel="noreferrer" target="_blank" className="client">
                                            <picture>
                                                <source srcSet={`${ ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(webp)'}) } 1x, ${ ImagesService(client.logo.filename, true, { path: '456x256/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                <source srcSet={`${ ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(client.logo.filename, true, { path: '456x256/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                <img loading="lazy" src={ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'})} alt={client.name} width="228" height="128" />
                                            </picture>
                                        </a>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="company-pr">
                            <h2>Referenzen</h2>
                            <ul className="clients-grid">
                            {this.blok.references_pr.map((client, index) => {
                                return (
                                    <li key={index}>
                                        <a href={client.website.cached_url} rel="noreferrer" target="_blank" className="client">
                                            <picture>
                                                <source srcSet={`${ ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(webp)'}) } 1x, ${ ImagesService(client.logo.filename, true, { path: '456x256/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                                <source srcSet={`${ ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(client.logo.filename, true, { path: '456x256/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                                <img loading="lazy" src={ImagesService(client.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'})} alt={client.name} width="228" height="128" />
                                            </picture>
                                        </a>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default ClientsOverview
