import React, { Component } from "react"
import StoryblokService from "../../../utils/storyblok-service"

import "./styles.scss"

class Textblock extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <div className="container">
                <div className="text-block" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render(this.blok.text)}} />
            </div>
          )
    }
}

export default Textblock