import React, { Component } from "react"
import SbEditable from "storyblok-react"
import ImagesService from '../../../utils/images-service'
import Button from '../../Button'

import "./styles.scss"


class TeamProfile extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="container">
                    <div className="team-profile">
                        <picture>
                            <source media="(max-width: 450px)" srcSet={`${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '450x210/smart/filters:format(webp)'}) } 1x, ${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '1968x920/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source media="(max-width: 450px)" srcSet={`${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '450x210/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '1968x920/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <source srcSet={`${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '984x460/smart/filters:format(webp)'}) } 1x, ${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '1968x920/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source srcSet={`${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '984x460/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '1968x920/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <img loading="lazy" src={ImagesService('https://a.storyblok.com/f/103293/5887x3312/1c43917519/dsc_6202-extended.jpg', true, { path: '984x460/smart/filters:format(jpeg):quality(60)'})} alt="Das Triaz PR Team" width="984" height="460" />
                        </picture>
                        <div className="names">
                            <div className="nametag left">
                                <div className="name">Stephanie Pennekamp</div><br/>
                                <div className="position">Inhaberin</div>
                                <div className="contact-options">
                                    <Button type="primary" to="/stephanie-pennekamp" label="Mehr erfahren" />
                                    <a href="https://www.linkedin.com/in/stephanie-pennekamp-ba921a1a3/" className="linkedin-link" title="LinkedIn-Profil öffnen" target="_blank" rel="noreferrer">LinkedIn</a>
                                </div>
                            </div>
                            <div className="nametag right">
                                <div className="name">Jens Conrad</div><br/>
                                <div className="position">Senior PR- & Projektmanager</div>
                                <div className="contact-options">
                                    <Button type="primary" to="/jens-conrad" label="Mehr erfahren" />
                                    <a href="https://www.linkedin.com/in/jens-conrad-69a9b7203/" className="linkedin-link" title="LinkedIn-Profil öffnen" target="_blank" rel="noreferrer">LinkedIn</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default TeamProfile