import React from "react"
import Placeholder from "../Placeholder/index"

import Hero from "../ContentTypes/Hero"
import Mantra from "../ContentTypes/Mantra"
import ClientsOverview from "../ContentTypes/ClientsOverview"
import TeamProfile from "../ContentTypes/TeamProfile"
import Textblock from "../ContentTypes/Textblock"
import MissionStatement from "../ContentTypes/MissionStatement"
import PoweredBy from "../ContentTypes/PoweredBy"

const Components = {
  "hero": Hero,
  "mantra": Mantra,
  "clientsOverview": ClientsOverview,
  "teamProfile": TeamProfile,
  "textblock": Textblock,
  "mission_statement": MissionStatement,
  "powered_by": PoweredBy
}

const Component = ({blok}) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    return <Component blok={blok} />
  }
  return blok.component ? <Placeholder componentName={blok.component}/> : null
}

export default Component