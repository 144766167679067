import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Button from "../../Button"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"


class Hero extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="hero-block">
                    <picture>
                        <source srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '1360x760/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '2720x1520/filters:format(webp)'}) } 2x`} type="image/webp" />
                        <source srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '1360x760/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '2720x1520/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                        <img loading="lazy" src={ImagesService(this.blok.image.filename, true, { path: '1360x760/filters:format(jpeg):quality(60)'})} alt={this.blok.image.alt} width="1360" height="760" />
                    </picture>
                    <div className="content">
                        <div className="container">
                            <div className="headline">
                                <h1>{this.blok.headline}</h1>
                            </div>
                            {this.blok.teaser !== "" &&
                                <div className="teaser">{this.blok.teaser}</div>
                            }
                            {this.blok.buttons.length !== 0 &&
                                <div className="buttons">
                                    {this.blok.buttons.map((button, index) => {
                                        let url = button.url.cached_url.replace("home", "")

                                        if (typeof button.url.anchor !== "undefined") {
                                            url += `#${button.url.anchor}`
                                        }

                                        return (
                                            <Button key={index} to={url} type={button.type} label={button.label} />
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Hero