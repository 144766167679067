import React, { Component } from "react"
import SbEditable from "storyblok-react"

import "./styles.scss"

class MissionStatement extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="container">
                    <div className="mission-statement">
                        {this.blok.letters.map((statement, index) => {
                            return (<div className="statement" key={index}>
                                <div className="letter">{statement.letter}</div>
                                <div className="definition">
                                    <h4>{statement.noun}</h4>
                                    <i>{statement.latin_definition}</i> {statement.description}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default MissionStatement